import React, { useState } from 'react';

import { useEffect } from 'react';
import { showErrorAlert } from '../../../../utils/alerts';
import { usersByApartment } from '../../../../utils/services/userService';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import UsersList from '../../../../components/UsersList';

const UsersByApartment = (props) => {
  const { apartmentId } = props.match.params;
  const { apartmentName } = props.location.state;

  const [users, setUsers] = useState([]);

  const getResidents = async () => {
    try {
      const users = await usersByApartment(apartmentId);
      if (users.length === 0) {
        await showErrorAlert('Error', 'No hemos encontrado usuarios registrados.');
      }
      setUsers(users);
    } catch (error) {
      console.error("Error", error);
      showErrorAlert('Error', error.message);
    }
  };

  useEffect(() => {
    getResidents();
  }, []);

  return (
    <div>
      <div className="mb-3 row w-100 text-form">
        <h2>
          Apartmaneto <b>{apartmentName}</b>
        </h2>
      </div>
      <div>
        <h3>Usuarios</h3>
      </div>
      <UsersList users={users} onUserSave={getResidents} />
    </div>
  );
};
UsersByApartment.propTypes = {
  apartmentId: PropTypes.text,
  match: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(UsersByApartment);
