import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';

import { deleteCardByCardId, getCardsByDocument, updateCardByCardId } from '../../../../utils/services/cardService';

import { showConfirmAlert, showErrorAlert, showSuccessAlert } from '../../../../utils/alerts';
import { CardRegister } from './CardRegister';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

const ListCards = ({ userDocument, userId }) => {
  const [cards, setCards] = useState([]);
  const [rowInEditMode, setRowInEditMode] = useState(null);
  const [valuesInEditMode, setValuesInEditMode] = useState({});

  const getListCardsByUserDocument = useCallback(async () => {
    try {
      const cardsData = await getCardsByDocument(userDocument);

      setCards(cardsData);
    } catch (error) {
      showErrorAlert('Error', error.message);
    }
  }, [userDocument]);

  const deleteCard = async ({ id }) => {
    try {
      await deleteCardByCardId(id);

      showSuccessAlert('Borrado Exitoso', 'Tarjeta borrada con éxito');
      getListCardsByUserDocument();
    } catch (error) {
      showErrorAlert('Error', error.message);
    }
  };

  const cardInEditMode = (id) => {
    try {
      setRowInEditMode(id);
      const currentRow = cards.find((item) => item.card.id === id).card;
      setValuesInEditMode(currentRow);
    } catch (error) {
      showErrorAlert('Error', error.message);
    }
  };

  const handleValuesInEditMode = (event) => {
    setValuesInEditMode({
      ...valuesInEditMode,
      [event.target.name]: event.target.value,
    });
  };

  const handleUpdateCard = async (id) => {
    try {
      const data = {
        type: valuesInEditMode.type,
        tag: valuesInEditMode.tag,
        active: valuesInEditMode.active,
      };

      await updateCardByCardId(id, data);

      setRowInEditMode(null);
      showSuccessAlert('Actualización exitosa', 'La tarjeta ha sido actualizada.');
      getListCardsByUserDocument();
    } catch (error) {
      showErrorAlert('Error', error.message);
    }
  };

  const cancelEditMode = () => {
    setRowInEditMode(null);
    setValuesInEditMode(null);
  };

  useEffect(() => {
    getListCardsByUserDocument();
  }, []);

  return (
    <>
      <div className="mb-3 row w-100 text-form">
        <h2>Administrar edificios</h2>
      </div>
      <h2>Tarjetas registradas</h2>
      <div className="mt-3 p-0 table-cards" style={{ width: '800px' }}>
        <table className="table table-striped">
          <thead className="text-center font-weight-bold">
            <tr>
              <th scope="col">Tag</th>
              <th scope="col">Tipo</th>
              <th scope="col">Último uso</th>
              <th scope="col">Estado</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {cards.map((item) => {
              const { card } = item;
              const { id, tag, type, lastTimeUsed, active } = card;
              return (
                <tr key={id}>
                  {rowInEditMode === id ? (
                    <>
                      <td className="align-middle w-25">
                        <input
                          type="text"
                          name="tag"
                          value={valuesInEditMode.tag}
                          onChange={handleValuesInEditMode}
                          className="form-control text-center w-100 h-100"
                          required
                        />
                      </td>
                      <td className="align-middle w-25">
                        <select
                          type="text"
                          name="type"
                          value={valuesInEditMode.type}
                          onChange={handleValuesInEditMode}
                          className="form-control text-center w-100"
                        >
                          <option value="PERSON">PERSON</option>
                          <option value="CAR">CAR</option>
                        </select>
                      </td>
                      <td className="align-middle w-25">{moment(lastTimeUsed).format('D MMM YYYY h:mm A')}</td>
                      <td className="align-middle w-100">
                        <input
                          type="checkbox"
                          name="active"
                          checked={valuesInEditMode.active}
                          onChange={(e) =>
                            handleValuesInEditMode({
                              target: {
                                name: e.target.name,
                                value: e.target.checked,
                              },
                            })
                          }
                        />
                      </td>

                      <td className="align-middle w-25">
                        <button
                          type="button"
                          className="btn btn-success p-1 mr-2"
                          onClick={() => {
                            showConfirmAlert({
                              title: 'Advertencia',
                              message: `¡Atención! Va a actualizar la información de la tarjeta ${tag}. ¿Está seguro de continuar?`,
                              onConfirm: () => {
                                handleUpdateCard(id);
                              },
                            });
                          }}
                        >
                          <span className="material-icons d-flex align-items-center">check</span>
                        </button>
                        <button type="button" className="btn btn-danger p-1" onClick={cancelEditMode}>
                          <span className="material-icons d-flex align-items-center">close</span>
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="align-middle">{tag}</td>
                      <td className="align-middle">{type}</td>
                      <td className="align-middle">{moment(lastTimeUsed).format('D MMM YYYY h:mm A')}</td>
                      <td className="align-middle">{active ? 'Activo' : 'Inactivo'}</td>
                      <td className="align-middle">
                        <button type="button" className="btn  p-1 mr-2" onClick={() => cardInEditMode(id)}>
                          <span className="material-icons d-flex align-items-center">edit</span>
                        </button>
                        <button
                          type="button"
                          className="btn p-1"
                          onClick={() => {
                            showConfirmAlert({
                              message: `Se borrará la tarjeta ${tag}. Esta acción no se puede deshacer.`,
                              title: 'Advertencia',
                              cancelButton: 'Cancelar',
                              continueButton: 'Borrar Tarjeta',
                              onConfirm: () => {
                                deleteCard({ id });
                              },
                            });
                          }}
                        >
                          <span className="material-icons d-flex align-items-center">delete</span>
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <CardRegister userId={userId} onCardSave={getListCardsByUserDocument} />
      </div>
    </>
  );
};
ListCards.propTypes = {
  userId: PropTypes.string,
  userDocument: PropTypes.number,
  onCardSave: PropTypes.func,
};

export { ListCards };
