import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { showConfirmAlert, showErrorAlert, showSuccessAlert, showConfirmAlertWithCheckbox } from '../utils/alerts';
import { updateUser, deactivateUser, activateUser } from '../utils/services/userService';

const UsersList = ({ users = [], onUserSave }) => {
  const [rowInEditMode, setRowInEditMode] = useState(null);
  const [valuesInEditMode, setValuesInEditMode] = useState({});

  const enterEditMode = (id) => {
    try {
      setRowInEditMode(id);
      const userData = users.find((item) => item.user.id === id);
      if (!userData) {
        showErrorAlert('Error', `No se encontró el usuario seleccionado.`);
        return;
      }

      setValuesInEditMode(userData.user);
    } catch (error) {
      console.error('Error', error);
      showErrorAlert('Error', `Error al intentar editar el usuario seleccionado.`);
    }
  };

  const handleValuesInEditMode = (event) => {
    setValuesInEditMode({
      ...valuesInEditMode,
      [event.target.name]: event.target.value,
    });
  };

  const handleUpdateUser = async (id) => {
    try {
      const data = {
        alias: valuesInEditMode.alias,
        phone: valuesInEditMode.phone,
      };

      await updateUser(id, data);

      cancelEditMode();
      showSuccessAlert('Actualización exitosa', 'El usuario ha sido actualizado.');
      onUserSave();
    } catch (error) {
      console.error('Error', error);
      showErrorAlert('Error', error.message);
    }
  };

  const cancelEditMode = () => {
    setRowInEditMode(null);
    setValuesInEditMode({});
  };

  const handleDeactivateUser = async (id, shouldDeactivateCards) => {
    try {
      await deactivateUser(id, shouldDeactivateCards);
      showSuccessAlert('Desactivación exitosa', 'El usuario ha sido desactivado.');
      onUserSave();
    } catch (error) {
      console.error('Error', error);
      showErrorAlert('Error', error.message);
    }
  };

  const handleActivateUser = async (id, shouldActivateCards) => {
    try {
      await activateUser(id, shouldActivateCards);
      showSuccessAlert('Activación exitosa', 'El usuario ha sido activado.');
      onUserSave();
    } catch (error) {
      console.error('Error', error);
      showErrorAlert('Error', error.message);
    }
  };

  const getNameInitials = (name) => {
    return name
      .split(' ')
      .map((word) => word[0].toUpperCase())
      .join('');
  };

  return (
    <div className="mt-3 p-0 table-users" style={{ minHeight: '300px', width: '1000px' }}>
      <table className="table table-striped">
        <thead className="text-center font-weight-bold">
          <tr>
            <th scope="col">Nombre completo</th>
            <th scope="col">Documento</th>
            <th scope="col">Citofonía?</th>
            <th scope="col">Activo?</th>
            <th scope="col">Email</th>
            <th scope="col">Teléfono</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {users.map((item) => {
            const { user } = item;
            const { id, name, cedula, alias, phone, email, active } = user;

            return (
              <tr key={id}>
                {rowInEditMode === id ? (
                  <>
                    <td className="align-middle">{name}</td>
                    <td className="align-middle">{cedula}</td>
                    <td className="align-middle">
                      <input
                        style={{ accentColor: 'rgb(34 197 94)' }}
                        type="checkbox"
                        name="alias"
                        checked={!!valuesInEditMode.alias}
                        onChange={(e) =>
                          handleValuesInEditMode({
                            target: {
                              name: e.target.name,
                              value: e.target.checked ? getNameInitials(name) : null,
                            },
                          })
                        }
                      />
                    </td>

                    <td className="align-middle">
                      <span className="material-icons">{active ? 'check' : 'close'}</span>
                    </td>
                    <td className="align-middle">{email}</td>
                    <td className="align-middle">
                      <input
                        type="tel"
                        name="phone"
                        value={valuesInEditMode.phone}
                        onChange={handleValuesInEditMode}
                        className="form-control text-center w-100"
                        required
                      />
                    </td>
                    <td className="align-middle">
                      <button
                        type="button"
                        className="btn btn-success p-1 mr-2"
                        onClick={() => {
                          showConfirmAlert({
                            title: 'Advertencia',
                            message: `¡Atención! Va a actualizar la información del usuario  ${cedula}. ¿Está seguro de continuar?`,
                            onConfirm: () => {
                              handleUpdateUser(id);
                            },
                          });
                        }}
                      >
                        <span className="material-icons d-flex align-items-center">check</span>
                      </button>
                      <button type="button" className="btn btn-danger p-1" onClick={cancelEditMode}>
                        <span className="material-icons d-flex align-items-center">close</span>
                      </button>
                    </td>
                  </>
                ) : (
                  <>
                    <td className="align-middle">{name}</td>
                    <td className="align-middle">{cedula}</td>
                    <td className="align-middle ">
                      <span className="material-icons">{alias ? 'check' : 'close'}</span>
                    </td>
                    <td className="align-middle">
                      <span className="material-icons">{active ? 'check' : 'close'}</span>
                    </td>
                    <td className="align-middle">{email}</td>
                    <td className="align-middle">{phone}</td>
                    <td className="align-middle">
                      <button type="button" className="btn p-1 mr-2" onClick={() => enterEditMode(id)}>
                        <span className="material-icons d-flex align-items-center">edit</span>
                      </button>
                      <button
                        type="checkbox"
                        className="btn p-1"
                        style={{
                          backgroundColor: active
                            ? 'rgb(34 197 94 / var(--tw-bg-opacity, 1))'
                            : 'rgb(220 38 38 / var(--tw-bg-opacity, 1))',
                        }}
                        onClick={() => {
                          showConfirmAlertWithCheckbox({
                            title: 'Advertencia',
                            messageCheckbox: active
                              ? `Desactivar las tarjetas asociadas al usuario.`
                              : `Activar las tarjetas asociadas al usuario.`,
                            message: active
                              ? `¡Atención! Vas a desactivar al usuario ${cedula}. ¿Estás seguro de continuar?`
                              : `¡Atención! Vas a activar al usuario ${cedula}. ¿Estás seguro de continuar?`,
                            onConfirm: (inputValue) => {
                              active
                                ? handleDeactivateUser(id, { shouldDeactivateCards: inputValue })
                                : handleActivateUser(id, { shouldActivateCards: inputValue });
                            },
                          });
                        }}
                      >
                        <span className="material-icons d-flex align-items-center" style={{ color: 'white' }}>
                          {active ? 'toggle_on' : 'toggle_off'}
                        </span>
                      </button>
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

UsersList.propTypes = {
  users: PropTypes.array,
  onUserSave: PropTypes.func,
};

export default UsersList;
