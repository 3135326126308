import React, { useEffect, useState } from 'react';
import { LogoPeque } from '../logo';
import { getMenuOptions, getCurrentUser } from '../utils/services/authService';
import { Link } from 'react-router-dom';

const Menu = () => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (token) {
          const currentUser = await getCurrentUser(token);
          const menuOptions = await getMenuOptions(currentUser.data);
          setOptions(menuOptions);
        }
      } catch (error) {
        console.error('Error', error);
      }
    };
    fetchData();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  return (
    <div id="navbarNavDropdown" className="menu-lateral collapse navbar-collapse">
      <LogoPeque />
      <nav>
        <ul>
          {options.map((option) => {
            if (!option) return null;
            return (
              <li key={option.path}>
                <Link to={option.path}>{option.label}</Link>
              </li>
            );
          })}
          <li>
            <a href="#" onClick={handleLogout}>
              Salir
            </a>
          </li>
        </ul>
      </nav>
      <p>
        Al continuar, aceptas las <a href="#">Condiciones del Servicio</a> y la <a href="#">Política de privacidad</a>{' '}
        de BlueBuilding.
      </p>
      <a
        className="menuclose"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      />
    </div>
  );
};

export default Menu;
