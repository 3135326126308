import swal from "sweetalert2";

const defaults = {
  success: {
    title: "¡Excelente!",
    message: "Proceso realizado con éxito",
  },
  error: {
    title: "Lo sentimos!",
    message: "Ha ocurrido un error.",
  },
};

export async function showSuccessAlert(title, message, useHtml) {
  try {
    const { success } = defaults;
    if (useHtml) {
      swal.fire({
        title: title || success.title,
        html: message,
        icon: "success",
      });
    } else {
      await swal.fire(
        title || success.title,
        message || success.message,
        "success"
      );
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function showErrorAlert(title, message) {
  try {
    const { error } = defaults;
    await swal.fire(title || error.title, message || error.message, "error");
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function showErrorList(errors = []) {
  try {
    const errorsList = errors?.length
      ? errors
      : ["Ha ocurrido un error, por favor intenta de nuevo"];

    const htmlErrors = errorsList.map(
      (error) => `<li class="list-group-item">${error}</li>`
    );

    await swal.fire({
      title: "Han ocurrido los siguientes errores",
      icon: "error",
      html: `<ul class="list-group">${htmlErrors}</ul>`,
    });
  } catch (error) {
    console.log({ error });
    return;
  }
}
export async function showConfirmAlert({
  title,
  message,
  confirmText = "Continuar",
  cancelButtonText = "Cancelar",
  onConfirm,
  onCancel,
}) {
  try {
    const { error } = defaults;
    const result = await swal.fire({
      icon: "warning",
      title: title || error.title,
      text: message || error.message,
      showCancelButton: true,
      confirmButtonText: confirmText,
      cancelButtonText: cancelButtonText,
    });

    if (result.isConfirmed) {
      onConfirm();
    } else {
      onCancel();
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function showConfirmAlertWithCheckbox({
  title,
  message,
  messageCheckbox,
  confirmText = "Continuar",
  cancelButtonText = "Cancelar",
  onConfirm,
  onCancel,
}) {
  try {
    const { error } = defaults;
    const result = await swal.fire({
      title: title || error.title,
      inputPlaceholder: messageCheckbox || error.message,
      text: message || error.message,
      showCancelButton: true,
      input: "checkbox",
      inputValue: 1,
      confirmButtonText: confirmText,
      cancelButtonText: cancelButtonText,
    });

    const inputValue = result.value;

    if (result.isConfirmed) {
      onConfirm(inputValue);
    } else {
      onCancel();
    }
  } catch (error) {
    console.error(error);
    return null;
  }

}
