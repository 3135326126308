import React from 'react';

import { withRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { showConfirmAlert, showSuccessAlert, showErrorAlert } from '../../utils/alerts';

import { getApartmentsByBuilding, deleteApartment } from '../../utils/api.js';

const Apartments = (props) => {
  const { history } = props;
  const { buildingId } = props.match.params;
  const [buildings, setBuildings] = useState([]);

  const getAllapartments = async () => {
    try {
      const data = await getApartmentsByBuilding(buildingId);

      setBuildings(data);
    } catch (error) {
      console.error("Error", error);
      showErrorAlert('Error', error.message);
    }
  };
  const confirmDeleteApartment = async ({ id }) => {
    try {
      await deleteApartment(buildingId, id);

      showSuccessAlert('Borrado Exitoso', 'Apartamento borrado con éxito');
    } catch (error) {
      console.error("Error", error);
      showErrorAlert('Error', error.message);
    }
  };

  const handleDeleteApartmentClick = (id) => {
    showConfirmAlert({
      message: 'Se borrará el apartamento y se creará uno nuevo.',
      title: 'Advertencia',
      cancelButton: 'Cancelar',
      continueButton: 'Borrar el Apartamento',
      onConfirm: () => {
        confirmDeleteApartment({ id });
      },
    });
  };
  useEffect(() => {
    getAllapartments();
  }, []);

  return (
    <>
      <div>
        <div className="row w-100 text-form">
          <h2>Administrar Apartamentos</h2>
        </div>

        <div className="container table-apartments">
          <table className="table table-striped">
            <thead className="text-center font-weight-bold text-white">
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Estado</th>
                <th scope="col">Acción</th>
              </tr>
            </thead>
            <tbody>
              {buildings
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((building) => {
                const { id, name, active } = building;
                return (
                  <tr key={id}>
                    <td className="align-middle">{name}</td>
                    <td className="align-middle">{active ? 'activo' : 'Inactivo'}</td>
                    <td className="align-middle">
                      <button
                        type="button"
                        className="btn p-1 mr-2"
                        onClick={() => {
                          handleDeleteApartmentClick(id);
                        }}
                      >
                        <span className="material-icons d-flex align-items-center">delete</span>
                      </button>
                      <button
                        type="button"
                        className="btn p-1"
                        onClick={() => {
                          history.push({
                            pathname: `/admin-dashboard/apartments/${id}/users`,
                            state: { apartmentName: name },
                          });
                        }}
                      >
                        <span className="material-icons d-flex align-items-center">visibility</span>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
Apartments.propTypes = {
  buildingId: PropTypes.text,
  history: PropTypes.object,
  match: PropTypes.object,
};
export default withRouter(Apartments);
